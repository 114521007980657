import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './dcodeMarkerAutomated2.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
import Text from "../../shared/Text/Text";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_dcodeMarkerAutomated2"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const DcodeMarkerAutomated2 = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const title = 'DMA - S/A Automated';

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'45%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'DMA-SA-Automated-CS.png', 'DMA- S/A automated')}
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'50%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text>
                    The automated marker is designed for hard wood marking based on Dcode. The levels of hardness require a
                    focused solution foreseeing the species specifics.
                </Text>
            </SectionTextWrapper>
        </div>
    )
};

export default DcodeMarkerAutomated2;
